<template>
    <modeloValorTipoCrud></modeloValorTipoCrud>
</template>

<script>
import modeloValorTipoCrud from '@/components/ModeloValorTipo/ModeloValorTipoCrud';

export default {
    name: 'modeloValorTipo',
    data() {
        return {};
    },
    components: {
        modeloValorTipoCrud
    }
};
</script>
