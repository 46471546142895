<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap dense>
                        <v-flex xs12>
                            <v-text-field v-model="item.id">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre de tipo">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <selectTipVehiculo v-model="item.tipVehiculo.id"
                                               :texto="item.tipVehiculo.nombre"
                                               @texto="item.tipVehiculo.nombre = $event">
                            </selectTipVehiculo>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancela</v-btn>
            <v-btn color="blue darken-1" text @click="saveItem">Guarda</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import selectTipvehiculo from '../../components/Selects/selectTipvehiculo';

    export default {
        name: 'modeloValorTipoDialogo',
        data() {
            return {
            };
        },

        computed: {
            ...mapState('modelovalortipo', ['item']),

            formTitle() {
                return this.index === -1 ? 'Nuevo Campo' : 'Edita Campo';
            }
        },

        methods: {
            ...mapActions('modelovalortipo', ['closeDialog', 'saveItem']),

            close() {
                this.closeDialog();
            }
        },

        components: {
            selectTipvehiculo
        }
    };
</script>
